import Vue from "vue";
import Component from "vue-class-component";
import { DocumentFolder } from "@/store/modules/document.store";
import { Action } from "vuex-class";
import { IndexDocumentPayload } from "@/store/modules/document.store";

@Component
export class HasDocuments extends Vue {
    @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

    /**
     * -----------------
     * Private variables
     * -----------------
     */

    documentsLoading = true;

    /**
     * -----------------
     * File getters
     * -----------------
     */

    /**
     * Get the current folder based on the route folder parameter
     *
     * @returns string
     */
    get currentFolder() {
        let folder = this.$route.query.folder;

        return folder ?? "";
    }

    /**
     * Get the parent folder based on the current folder
     *
     * @returns string
     */
    get parentFolder() {
        switch (this.currentFolder) {
            case DocumentFolder.syndic:
                return DocumentFolder.legalProvisions;
                break;
            case DocumentFolder.openSalesAgreement:
            case DocumentFolder.closedSalesAgreement:
            case DocumentFolder.approvedSalesAgreement:
            case DocumentFolder.salesAgreementSub:
                return DocumentFolder.salesAgreement;
                break;
            case DocumentFolder.advisuersfiche:
            case DocumentFolder.board:
            case DocumentFolder.pictures:
            case DocumentFolder.measurementPlan:
            case DocumentFolder.socialMedia:
                return DocumentFolder.photos;
                break;
            case DocumentFolder.vgeEpc:
            case DocumentFolder.vgeEk:
            case DocumentFolder.vgeAsbestos:
            case DocumentFolder.vgeImmoSigns:
                return DocumentFolder.vge;
                break;
            case DocumentFolder.vge:
            case DocumentFolder.asbestosProject:
            case DocumentFolder.otherInspections:
                return DocumentFolder.inspections;
                break;
            default:
                return "";
                break;
        }
    }

    /**
     * Get the child folders based on the current folder
     *
     * @returns string[]
     */
    get childFolders() {
        return this.getChildFolders(this.currentFolder);
    }

    /**
     * Get the recursive child folders based on the current folder
     *
     * @returns { [key: string]: DocumentFolder[] }
     */
    get recursiveChildFolders() {
        let folders: { [key: string]: DocumentFolder[] } = {};
        let currentFolder = this.currentFolder == "" ? "pwd" : this.currentFolder;
        let childFolders = this.getChildFolders(currentFolder);

        childFolders.forEach((f: DocumentFolder) => {
            folders[f] = this.getChildFolders(f);

            folders[f].forEach((f2: DocumentFolder) => {
                folders[f] = folders[f].concat(this.getChildFolders(f2));
            });
        });

        return folders;
    }

    /**
     * Get the recursive child folders based on the current folder
     *
     * @returns { [key: string]: DocumentFolder[] }
     */
    getChildFolders(folder: string | (string | null)[]) {
        switch (folder) {
            case DocumentFolder.legalProvisions:
                return [DocumentFolder.syndic];
                break;
            case DocumentFolder.salesAgreement:
                return [DocumentFolder.openSalesAgreement, DocumentFolder.approvedSalesAgreement, DocumentFolder.salesAgreementSub];
                break;
            case DocumentFolder.inspections:
                return [DocumentFolder.vge, DocumentFolder.asbestosProject, DocumentFolder.otherInspections];
                break;
            case DocumentFolder.photos:
                return [DocumentFolder.advisuersfiche, DocumentFolder.board, DocumentFolder.pictures, DocumentFolder.measurementPlan, DocumentFolder.socialMedia];
                break;
            case DocumentFolder.vge:
                return [DocumentFolder.vgeEpc, DocumentFolder.vgeEk, DocumentFolder.vgeAsbestos, DocumentFolder.vgeImmoSigns];
                break;
            case "pwd":
            case "":
                return [DocumentFolder.mediationAgreement, DocumentFolder.legalProvisions, DocumentFolder.inspections, DocumentFolder.photos, DocumentFolder.salesAgreement, DocumentFolder.varia, DocumentFolder.invoice];
                break;
            default:
                return [];
                break;
        }
    }

    /**
     * -----------------
     * File methods
     * -----------------
     */

    /**
     * Get the documents based on the activity id
     *
     * @param activityId: number
     *
     * @returns void
     **/
    async getActivityDocuments(activityId: number) {
        this.documentsLoading = true;

        await this.indexDocuments({ per_page: 250, q: { and: { activity_id: activityId } } });

        this.documentsLoading = false;
    }

    /**
     * Handle the folder opened event
     *
     * @param folder: DocumentFolder
     *
     * @returns void
     **/
    handleFolderOpened(folder: DocumentFolder) {
        this.$router.push({ name: this.$route.name as string, params: this.$route.params, query: { ...this.$route.query, folder: folder } });
    }

    /**
     * Handle navigate to parent folder based on the currentfolder
     *
     * @param callback: () => void
     *
     * @returns void
     **/
    handleGoToParentFolder(callback?: () => void) {
        this.$router.push({ name: this.$route.name as string, params: this.$route.params, query: { ...this.$route.query, folder: this.parentFolder } });

        if (callback) {
            callback();
        }
    }

    /**
     * Handle navigate to root folder
     *
     * @param callback: () => void
     *
     * @returns void
     **/
    handleGoToRootFolder(callback?: () => void) {
        this.$router.push({ name: this.$route.name as string, params: this.$route.params, query: { ...this.$route.query, folder: null } });

        if (callback) {
            callback();
        }
    }
}
