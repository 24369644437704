
import { Component, Mixins, Prop } from "vue-property-decorator";
import { IndexDocumentPayload } from "@/store/modules/document.store";
import { Getter, Action } from "vuex-class";
import { HasDocuments } from "@/mixins/has-documents";

@Component({
    components: {
        documentGrid: () => import("@/components/document/grid.vue"),
        PageTitle: () => import("@/views/seller/parts/general/page-title.vue"),
        previewMediaModal: () => import("@/components/modals/preview-media.vue"),
    },
})
export default class PageSellerDocuments extends Mixins(HasDocuments) {
    @Getter("document/all") documents!: Document[];
    @Action("document/index") indexDocuments!: (payload: IndexDocumentPayload) => Promise<Document[]>;

    @Prop({ default: true }) canSearch!: boolean;

    loading = true;

    search = "";

    async mounted() {
        await this.indexDocuments({ per_page: 250, q: { and: { activity_id: Number(this.$route.params.id) } } });

        this.loading = false;
    }
}
